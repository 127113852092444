import {MODAL_STYLE, MODAL_STYLE_STREAM_VIDEO} from 'constants/modalOverlay';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import Modal from 'react-modal';
import modalService from 'store/modalService';
import './threadModal.scss';
import {Chat} from 'pages/main/components/chat';
import {MainBottom} from 'pages/main/components/mainBottom';
import streamService from 'store/streamService';
import classNames from 'classnames';
import appService from 'store/appService';
import {VideoPosition} from 'models/enums/VideoPosition.enum';
import ThreadHeader from './ThreadHeader';

interface IThreadModal {
	isStreamVideo?: boolean;
}

const ThreadModal: FunctionComponent<IThreadModal> = function ThreadModal({isStreamVideo}) {
	const {visibleThreadModal} = useLocalObservable(() => modalService);
	const {isLayoutSwitched, isScreenSharing, streamAspectRatio, verticalStreamHeight} =
		useLocalObservable(() => streamService);
	const {videoPosition} = useLocalObservable(() => appService);

	const cssVarPanelTopTransparent = getComputedStyle(document.documentElement).getPropertyValue(
		'--panel-top-transparent'
	);

	const threadModalClasses = classNames('thread-modal', {
		'thread-modal--switched-layout': isLayoutSwitched,
	});

	const getOverlayStyleTop = () => {
		if (isStreamVideo) {
			return MODAL_STYLE_STREAM_VIDEO.overlay;
		}

		if (isLayoutSwitched) {
			return {...MODAL_STYLE.overlay, top: '0', height: '100%', right: 0, left: 'auto'};
		}

		if (isScreenSharing) {
			type AspectRatio = '1:1' | '16:9' | '4:3' | '3:4';

			const OFFSET_1_1 = 375;
			const OFFSET_16_9 = 211;
			const OFFSET_4_3 = 281;
			const OFFSET_3_4 = verticalStreamHeight;

			const aspectRatioOverMapping: Record<AspectRatio, string> = {
				'1:1': `${OFFSET_1_1}px`,
				'16:9': `${OFFSET_16_9}px`,
				'4:3': `${OFFSET_4_3}px`,
				'3:4': `${OFFSET_3_4}px`,
			};

			const over = aspectRatioOverMapping[streamAspectRatio as AspectRatio] || '211px';
			const headerTransparent = cssVarPanelTopTransparent && JSON.parse(cssVarPanelTopTransparent);
			const headerOffset = headerTransparent ? 0 : 44;

			const appLower = `calc(${over} + ${headerOffset}px)`;

			const style = {...MODAL_STYLE.overlay, top: '0', height: 'auto'};

			if (videoPosition === VideoPosition.FLOATING) {
				style.top = '0';
			} else if (videoPosition === VideoPosition.UNDER) {
				style.top = appLower;
			} else if (videoPosition === VideoPosition.OVER) {
				style.top = over;
			} else if (isLayoutSwitched) {
				style.height = '100%';
			}

			return style;
		}

		return {...MODAL_STYLE.overlay};
	};

	return (
		<Modal
			className='modal modal--thread'
			isOpen={visibleThreadModal}
			ariaHideApp={false}
			style={{
				overlay: getOverlayStyleTop(),
			}}>
			<section className={threadModalClasses}>
				<ThreadHeader />
				<Chat isThread />
				<MainBottom />
			</section>
		</Modal>
	);
};

export default observer(ThreadModal);
