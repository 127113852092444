import {MODAL_STYLE_CENTER} from 'constants/modalOverlay';
import modalService from 'store/modalService';
import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import welcomeService from 'store/welcomeService';
import './welcomeModal.scss';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';
import useAnalytics from 'hooks/useAnalytics';
import classNames from 'classnames';
import useAgora from 'hooks/useAgora';
import useUser from 'hooks/useUser';
import userService from 'store/userService';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

const WelcomeModal: FunctionComponent = function WelcomeModal() {
	const {welcomeMessage} = useLocalObservable(() => welcomeService);
	const {welcomeModalVisible, toggleWelcomeModalVisible} = useLocalObservable(() => modalService);
	const {appIcons, deviceOs, appVoice, appEnableAgora, isAgoraLoaded, iosCheckVersionAlertShown} =
		useLocalObservable(() => appService);
	const {userData} = useLocalObservable(() => userService);

	const {checkForSupportMicrophoneAndSpeaker} = useAgora();
	const {userExtraDataCheckOffer, userExtraDataCheckBadge} = useUser();

	const {getAppIcon} = useAppData();
	const {IcoClose} = appIcons;
	const {sendAnalytics} = useAnalytics();

	const [isCentered, setIsCentered] = useState(false);

	const headClassNames = classNames('welcome-modal__head', {
		'welcome-modal__head--centered': isCentered,
	});

	if (!welcomeModalVisible || !welcomeMessage) {
		return null;
	}

	const onAfterOpenHandler = () => {
		const elem = document.querySelector('.welcome-modal__text p');
		if (elem && window.getComputedStyle(elem).textAlign === 'center') {
			setIsCentered(true);
		} else setIsCentered(false);
	};

	const onAfterCloseHandler = () => {
		if (appVoice && appEnableAgora && isAgoraLoaded && deviceOs && !iosCheckVersionAlertShown) {
			checkForSupportMicrophoneAndSpeaker();
		}

		if (userData) {
			if (!userExtraDataCheckBadge(userData, false)) userExtraDataCheckOffer(userData, false);
		}
	};

	const linkPostMessage = () => {
		const msg = {
			type: 'link',
			body: {
				action: 'open',
				data: {
					link: welcomeMessage.buttonLink,
				},
			},
		};

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}
		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(msg, '*');
	};

	const onBtnClickHandler = () => {
		sendAnalytics('ip_button_pressed', {
			header: welcomeMessage?.title || 'none',
			button_text: welcomeMessage?.buttonText,
			content: welcomeMessage?.buttonLink || 'close',
		});

		if (welcomeMessage.buttonLink) {
			if (welcomeMessage.openType === LinkOpenType.NEW_WINDOW) {
				window.open(welcomeMessage.buttonLink, '_blank');
				return;
			}
			if (welcomeMessage.openType === LinkOpenType.CURRENT_WINDOW) {
				window.open(welcomeMessage.buttonLink, '_self');
				return;
			}
			if (welcomeMessage.openType === LinkOpenType.POST_MESSAGE) {
				linkPostMessage();
				return;
			}
		}
		toggleWelcomeModalVisible(false);
	};

	const onCloseClickHandler = () => {
		toggleWelcomeModalVisible(false);
	};

	return (
		<Modal
			className='modal modal--welcome'
			isOpen={welcomeModalVisible}
			onAfterOpen={onAfterOpenHandler}
			onAfterClose={onAfterCloseHandler}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE_CENTER.overlay,
			}}>
			<div className='modal__body'>
				{(welcomeMessage.buttonLink || !welcomeMessage.buttonText) && (
					<Button className='welcome-modal__close' onClick={onCloseClickHandler}>
						{getAppIcon(IcoClose.pic)}
					</Button>
				)}
				<div className='modal__axis-y'>
					<section className='welcome-modal'>
						{welcomeMessage.title && (
							<div className={headClassNames}>
								<div className='welcome-modal__title'>{welcomeMessage.title}</div>
							</div>
						)}
						<div className='welcome-modal__body'>
							<div
								className='welcome-modal__text'
								dangerouslySetInnerHTML={{__html: welcomeMessage.text}}
							/>
						</div>
						{welcomeMessage.buttonText && (
							<div className='welcome-modal__footer'>
								<Button className='welcome-modal__footer-btn btn' onClick={onBtnClickHandler}>
									{welcomeMessage.buttonText}
								</Button>
							</div>
						)}
					</section>
				</div>
			</div>
		</Modal>
	);
};

export default observer(WelcomeModal);
