import {AppEmotion} from 'models/app';
import emotionServices from 'store/emotionService';
import streamService from 'store/streamService';
import classNames from 'classnames';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import EmotionAnimItem from './EmotionAnimItem';
import './emotions-anim.scss';

const EmotionsAnim: FunctionComponent = function EmotionsAnim() {
	const {emotionsAnim} = useLocalObservable(() => emotionServices);
	const {isFullScreenVideo, isFullscreenMessagesShown} = useLocalObservable(() => streamService);

	const emotionsClasses = classNames('emotions-anim', {
		'emotions-anim--fullscreen': isFullScreenVideo && isFullscreenMessagesShown,
	});

	const renderEmotionAnim = useCallback(
		(emotion: AppEmotion) => <EmotionAnimItem key={emotion.uuid} emotion={emotion} />,
		[emotionsAnim]
	);

	if (!emotionsAnim.length) {
		return null;
	}

	return <div className={emotionsClasses}>{emotionsAnim.map(renderEmotionAnim)}</div>;
};

export default observer(EmotionsAnim);
