import {MODAL_STYLE} from 'constants/modalOverlay';
import modalServices from 'store/modalService';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {BlockedUsers} from 'components/blockedUsers';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {AppScreens} from 'models/enums/AppScreens.enum';
import appService from 'store/appService';
import {useEffect} from 'react';
import Button from 'components/hoc/Button';

const BlockedModal: React.FC = function BlockedModal() {
	const {blockedModalVisible, hideBlockedModal} = useLocalObservable(() => modalServices);
	const {changeCurrentScreen, appIcons} = useLocalObservable(() => appService);
	const translations = useL10n();

	const {getAppIcon} = useAppData();

	const {IcoClose} = appIcons;

	useEffect(() => {
		blockedModalVisible
			? changeCurrentScreen(AppScreens.BLOCKLIST)
			: changeCurrentScreen(AppScreens.SETTINGS);
	}, [blockedModalVisible]);

	if (!blockedModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal'
			isOpen={blockedModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{translations.blockedScreen.title}</div>
				<Button className='modal__close' onClick={hideBlockedModal}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<BlockedUsers />
				</div>
			</div>
		</Modal>
	);
};

export default observer(BlockedModal);
