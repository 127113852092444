/* eslint-disable max-lines */
import {AlertBtnType} from 'models/enums/Alert.enum';
import appService from 'store/appService';
import userService from 'store/userService';
import roomServices from 'store/roomService';
import alertService from 'store/alertService';
import toastService from 'store/toastService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import useMessage from 'hooks/useMessage';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import './pinnedMessage.scss';
import classNames from 'classnames';
import {getNextElementInArray, getPreviousElementInArray} from 'utils/helpers';
import streamService from 'store/streamService';
import {Message} from 'models/room';

interface IPinnedMessageProps {
	handleClick: (messageId: number) => Promise<void>;
}

const PinnedMessage: FunctionComponent<IPinnedMessageProps> = function PinnedMessage(props) {
	const {handleClick} = props;
	const {appReadOnly, appIcons} = useLocalObservable(() => appService);
	const {isUserExternalIdCorrect, userData} = useLocalObservable(() => userService);
	const {
		roomId,
		myTalker,
		pinnedMessages,
		activePinnedMessage,
		setActivePinnedMessage,
		localMessages,
	} = useLocalObservable(() => roomServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);
	const {isLayoutSwitched} = useLocalObservable(() => streamService);

	const {alerts, toasts, pinnedMessage: pinnedMessageTranslate} = useL10n();
	const {getAppIcon} = useAppData();
	const {getMessageText} = useMessage();

	const [slicedPinnedMessages, setSlicedPinnedMessages] = useState<any[]>([]);
	const isPinnedClicked = useRef<boolean>(false);
	const intersectingPinnedElems = useRef<{id: number; top: number; bottom: number}[]>([]); // array of visible on the screen pinned elems
	const messagesRefs = useRef<Set<HTMLElement | null>>(new Set([]));
	const activePinnedMessageRef = useRef<any>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const {IcoPin} = appIcons;

	const talkerIsModer = !!myTalker?.isModer || userData?.isModer;

	const cssVarPanelTopTransparent = getComputedStyle(document.documentElement).getPropertyValue(
		'--panel-top-transparent'
	);
	const pinnedMessageClasses = classNames('pinned-message', {
		'pinned-message--top': cssVarPanelTopTransparent && JSON.parse(cssVarPanelTopTransparent),
		'pinned-message--switched-layout': isLayoutSwitched,
	});

	const pinnedMessageTextClasses = (pinnedMessageId: number) =>
		classNames('pinned-message__text', {
			'pinned-message__text--active': pinnedMessageId === activePinnedMessage?.id,
		});
	const pinnedMessagePaginationItemClasses = (pinnedMessageId: number, index: number) => {
		const pinnedMessageIndex = pinnedMessages.findIndex(
			element => element.id === activePinnedMessage?.id
		);
		return classNames('pinned-message__pagination-item', {
			'pinned-message__pagination-item--active':
				(pinnedMessages.length < 6 && activePinnedMessage?.id === pinnedMessageId) ||
				(pinnedMessages.length > 5 && index === 0 && pinnedMessageIndex === 0) ||
				(pinnedMessages.length > 5 && index === 1 && pinnedMessageIndex === 1) ||
				(pinnedMessages.length > 5 &&
					index === 2 &&
					pinnedMessageIndex <= pinnedMessages.length - 3 &&
					pinnedMessageIndex > 1) ||
				(pinnedMessages.length > 5 &&
					index === 3 &&
					pinnedMessageIndex === pinnedMessages.length - 2) ||
				(pinnedMessages.length > 5 &&
					index === 4 &&
					pinnedMessageIndex === pinnedMessages.length - 1),
		});
	};

	const unpinMessage = () => {
		if (!appReadOnly && isUserExternalIdCorrect && roomId && activePinnedMessage) {
			showAlert({
				title: alerts.unPinMessage.title,
				buttons: [
					{
						text: alerts.btns.unpin,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							SocketIoServices.emitUnpinMessage({
								externalRoomId: roomId,
								messageId: activePinnedMessage.id,
							});
							addToast({
								iconName: ToastIconsName.unPin,
								liveTime: 3000,
								message: toasts.messageUnpinned,
								cancellable: true,
							});
							hideAlert();
						},
					},
					{
						text: alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: hideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
	};

	const updateIntersectingPinnedElems = (messageId: number, top: number, bottom: number) => {
		if (!intersectingPinnedElems.current.find(el => el.id === messageId)) {
			intersectingPinnedElems.current.push({id: messageId, top, bottom});
		} else {
			intersectingPinnedElems.current = intersectingPinnedElems.current.map(el => {
				if (el.id === messageId) return {id: messageId, top, bottom};
				return el;
			});
		}
	};

	const handleIntersectingEntry = (
		top: number,
		bottom: number,
		currentElementId: number,
		nextPinnedMessage: Message | undefined
	) => {
		if (pinnedMessages.find(el => el.id === currentElementId)) {
			// add or update pinned element to array if is visible
			updateIntersectingPinnedElems(currentElementId, top, bottom);
		}

		const lastIntersectingPinnedElem =
			intersectingPinnedElems.current[intersectingPinnedElems.current.length - 1];
		const pinnedMessageElem = pinnedMessages.find(el => el.id === lastIntersectingPinnedElem?.id);

		// fix updating active pinned message if scroll bottom
		if (lastIntersectingPinnedElem && lastIntersectingPinnedElem.top < top && pinnedMessageElem) {
			setActivePinnedMessage(pinnedMessageElem);
			return;
		}

		if (
			nextPinnedMessage &&
			currentElementId !== activePinnedMessageRef.current?.id &&
			currentElementId > nextPinnedMessage?.id &&
			bottom > 100
		) {
			setActivePinnedMessage(nextPinnedMessage);
		}
	};

	const handleNonIntersectingEntry = (
		top: number,
		bottom: number,
		currentElementId: number,
		prevPinnedMessage: Message | undefined
	) => {
		if (intersectingPinnedElems.current.find(el => el.id === currentElementId)) {
			// remove not visible pinned element from array
			intersectingPinnedElems.current = intersectingPinnedElems.current.filter(
				el => el.id !== currentElementId
			);
		}

		if (currentElementId <= activePinnedMessageRef.current?.id && top > 100) {
			if (prevPinnedMessage) setActivePinnedMessage(prevPinnedMessage);
		}
	};

	const handleIntersection = (entry: IntersectionObserverEntry) => {
		const {top, bottom} = entry.boundingClientRect;
		const currentElement = entry.target as HTMLDivElement;
		const currentElementId = Number(currentElement.getAttribute('data-id'));

		const prevPinnedMessage = getPreviousElementInArray(
			pinnedMessages,
			activePinnedMessageRef.current?.id
		);
		const nextPinnedMessage = getNextElementInArray(
			pinnedMessages,
			activePinnedMessageRef.current?.id
		);

		if (entry.isIntersecting) {
			handleIntersectingEntry(top, bottom, currentElementId, nextPinnedMessage);
		} else {
			handleNonIntersectingEntry(top, bottom, currentElementId, prevPinnedMessage);
		}
	};

	const _observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => handleIntersection(entry));
		},
		{rootMargin: '100px 0px 100px 0px', threshold: [0, 0.25, 0.5, 0.75, 1]}
	);

	const clickOnPinnedMessageHandler = async () => {
		if (!appReadOnly && isUserExternalIdCorrect && activePinnedMessage !== null) {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = null;
			}

			isPinnedClicked.current = true;
			messagesRefs.current.forEach(messageElement => {
				if (messageElement) _observer.unobserve(messageElement);
			});
			await handleClick(activePinnedMessage.id);
			timeoutRef.current = setTimeout(() => {
				isPinnedClicked.current = false;
			}, 1000);

			const prevPinnedMessage = getPreviousElementInArray(pinnedMessages, activePinnedMessage.id);
			if (prevPinnedMessage) setActivePinnedMessage(prevPinnedMessage);
			else setActivePinnedMessage(pinnedMessages[pinnedMessages.length - 1]);
		}
	};

	useEffect(() => {
		if (pinnedMessages.length) {
			if (pinnedMessages.length > 5) {
				setSlicedPinnedMessages(pinnedMessages.slice(0, 5));
			} else {
				setSlicedPinnedMessages([...pinnedMessages]);
			}
		}
	}, [pinnedMessages.length]);

	useEffect(() => {
		if (!isPinnedClicked.current) {
			messagesRefs.current.forEach(messageElement => {
				if (messageElement) _observer.observe(messageElement);
			});
		}

		return () => {
			messagesRefs.current.forEach(messageElement => {
				if (messageElement) _observer.unobserve(messageElement);
			});
		};
	}, [
		messagesRefs.current.size,
		pinnedMessages.length,
		activePinnedMessageRef.current?.id,
		isPinnedClicked.current,
	]);

	useEffect(() => {
		if (activePinnedMessage) {
			setTimeout(() => {
				activePinnedMessageRef.current = activePinnedMessage;
				localMessages.forEach(message => {
					const element = document.querySelector(
						`.chat__message[data-id="${message?.id}"]`
					) as HTMLElement;

					if (element) {
						messagesRefs.current.add(element);
					}
				});
			}, 1000);
		}
	}, [activePinnedMessage, localMessages.length]);

	return (
		<div className={pinnedMessageClasses} data-hint='pin'>
			<div className='pinned-message__container'>
				<Button onClick={clickOnPinnedMessageHandler} className='pinned-message__scrollbtn' />
				{pinnedMessages && pinnedMessages?.length > 1 && (
					<div className='pinned-message__pagination'>
						{slicedPinnedMessages.map((el: any, index: number) => {
							return (
								<span
									key={el.id}
									className={pinnedMessagePaginationItemClasses(el.id, index)}
									style={{
										height: `${
											32 / slicedPinnedMessages.length - 3 / (slicedPinnedMessages.length - 1)
										}px`,
									}}
								/>
							);
						})}
					</div>
				)}
				{getAppIcon(IcoPin.pic, {className: 'pinned-message__icon'})}

				<div className='pinned-message__body'>
					<p className='pinned-message__title'>{pinnedMessageTranslate.title}</p>
					<div className='pinned-message__wrap'>
						<div
							className='pinned-message__elems'
							style={{
								transform: `translateY(-${
									14 * pinnedMessages.findIndex(element => element?.id === activePinnedMessage?.id)
								}px)`,
							}}>
							{pinnedMessages.map(el => {
								return (
									el && (
										<p className={pinnedMessageTextClasses(el.id)} key={el.id}>
											{(el && getMessageText(el)) ||
												el.advertisement?.title ||
												el.advertisement?.text}
										</p>
									)
								);
							})}
						</div>
					</div>
				</div>
			</div>

			{talkerIsModer && <Button className='pinned-message__unpin' onClick={unpinMessage} />}
		</div>
	);
};

export default observer(PinnedMessage);
