import appService from 'store/appService';
import modalServices from 'store/modalService';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import Button from 'components/hoc/Button';
import {Language} from 'components/Language';
import useTranslation from 'hooks/useTranslation';
import useModal from 'hooks/useModal';

const LanguagesModal: React.FC = function LanguagesModal() {
	const {languageScreen, btns, modals} = useL10n();
	const {appIcons} = useLocalObservable(() => appService);
	const {
		languagesModalVisible,
		toggleLanguagesModalVisible,
		toggleTranslateModalVisible,
		settingsModalVisible,
	} = useLocalObservable(() => modalServices);

	const {getAppIcon} = useAppData();
	const {IcoClose} = appIcons;
	const {turnOnTranslation} = useTranslation();
	const {getOverlayStyle, getOverlayStyleWidth, getOverlayStyleHeight} = useModal();

	const onClickSaveHandler = () => {
		toggleLanguagesModalVisible(false);
		toggleTranslateModalVisible(false);
		turnOnTranslation();
	};

	return (
		<Modal
			className='modal modal--langs'
			isOpen={languagesModalVisible}
			ariaHideApp={false}
			style={{
				overlay: {...getOverlayStyle(), zIndex: 10000},
				content: {
					width: getOverlayStyleWidth(),
					height: getOverlayStyleHeight(),
				},
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{languageScreen.translateMessagesTo}</div>
				<Button className='modal__close' onClick={() => toggleLanguagesModalVisible(false)}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<Language />
				</div>
				<div className='modal__btn'>
					<Button className='btn' onClick={onClickSaveHandler}>
						{settingsModalVisible ? btns.save : modals.translate.btn}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default observer(LanguagesModal);
