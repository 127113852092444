import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';

import useAppData from 'hooks/useAppData';
import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalService';
import betServices from 'store/copybettingService';
import appService from 'store/appService';
import roomService from 'store/roomService';

import {MODAL_STYLE} from 'constants/modalOverlay';
import {bets as staticBets} from 'constants/copybettingStandBets';

import {Bet} from 'models/bet';

import Button from 'components/hoc/Button';
import BetItem from 'components/bet/BetItem';

import './shareBet.scss';
import useBet from 'hooks/useBet';

import debounce from 'lodash/debounce';

import CopybettingImg from 'assets/images/copybetting-img.png';

import ShareBetSkeleton from './ShareBetSkeleton';
import ShareBetPreloader from './ShareBetPreloader';

const ShareBetModal: FunctionComponent = function ShareBet() {
	const {modals} = useL10n();
	const {appIcons, project, copybettingVersion, isStand} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomService);
	const {shareBetModal, toggleShareBetModal} = useLocalObservable(() => modalServices);
	const {bets, clearBets, betsError, setBets} = useLocalObservable(() => betServices);
	const [visiblePreloaderBottom, setVisiblePreloaderBottom] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {getBetsWithServices, checkScrollBottom} = useBet();

	const userBetsRef = useRef<HTMLDivElement>(null);
	const userBetsScrollRef = useRef<HTMLDivElement>(null);

	const {getAppIcon} = useAppData();
	const {IcoClose} = appIcons;

	const isBetStand = isStand && roomId?.includes('betting-');

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				checkScrollBottom(scrollEvent, userBetsScrollRef.current, setVisiblePreloaderBottom),
			50
		),
		[bets, visiblePreloader, visiblePreloaderBottom]
	);

	const getBets = async () => {
		setVisiblePreloader(true);
		await getBetsWithServices();
		setVisiblePreloader(false);
	};

	const closeClickHandler = () => {
		clearBets();
		toggleShareBetModal(false);
	};

	useEffect(() => {
		if (!isBetStand && project !== 'dev') {
			if (shareBetModal) getBets();
			return;
		}
		setBets(staticBets, 0);
	}, [shareBetModal]);

	const renderUserBet = (item: Bet, key: number | string) => (
		<BetItem key={key} bet={item} isShareBetModal />
	);

	if (!shareBetModal) {
		return null;
	}

	return (
		<Modal
			className='modal modal--share-bet'
			isOpen={shareBetModal}
			ariaHideApp={false}
			style={{
				overlay:
					copybettingVersion === 1
						? MODAL_STYLE.overlay
						: {...MODAL_STYLE.overlay, zIndex: 10001, top: '20%'},
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.shareBet.title}</div>
				<Button className='modal__close' onClick={closeClickHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='share-bet'>
						<ShareBetSkeleton visible={visiblePreloader} />
						{betsError && <p className='share-bet__error'>{betsError}</p>}
						{!bets[0].length && !betsError && !visiblePreloader ? (
							<div className='share-bet__stub'>
								<div className='share-bet__stub-pic'>
									<img className='share-bet__stub-img' src={CopybettingImg} alt='' />
								</div>
								<div className='share-bet__stub-content'>
									<p className='share-bet__stub-title'>{modals.shareBet.noBetsTitle}</p>
									<p className='share-bet__stub-text'>{modals.shareBet.noBetsText}</p>
								</div>
							</div>
						) : (
							!visiblePreloader && (
								<div
									className='share-bet__axis-y'
									onScroll={onScrollHandler}
									ref={userBetsScrollRef}>
									<div className='share-bet__list' ref={userBetsRef}>
										{bets.map(item => item.map(renderUserBet))}
									</div>

									{visiblePreloaderBottom && <ShareBetPreloader />}
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(ShareBetModal);
