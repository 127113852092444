import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect} from 'react';
import {MODAL_STYLE} from 'constants/modalOverlay';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';
import modalService from 'store/modalService';
import useL10n from 'l10n/useL10n';
import './translateModal.scss';
import userService from 'store/userService';
import useTranslation from 'hooks/useTranslation';

const TranslateModal: FunctionComponent = function TranslateModal() {
	const {modals} = useL10n();
	const {getAppIcon} = useAppData();
	const {turnOnTranslation} = useTranslation();

	const {appIcons, translateLangs} = useLocalObservable(() => appService);
	const {translateMode} = useLocalObservable(() => userService);
	const {IcoClose, IcoChevron} = appIcons;
	const {translateModalVisible, toggleTranslateModalVisible, toggleLanguagesModalVisible} =
		useLocalObservable(() => modalService);

	const onModalClosehandler = () => {
		toggleTranslateModalVisible(false);
	};

	const onClickLanguageHandler = () => {
		toggleLanguagesModalVisible(true);
	};

	const onClickSaveHandler = () => {
		toggleTranslateModalVisible(false);
		turnOnTranslation();
	};

	return (
		<Modal
			className='modal modal--autoheight modal--translate'
			isOpen={translateModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.translate.title}</div>
				<Button className='modal__close' aria-label='modal-close' onClick={onModalClosehandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<span className='translate-modal__label'>{modals.translate.chooseLanguage}</span>
					<div className='settings'>
						<div className='settings__items'>
							<div className='settings__item'>
								<Button className='settings__button' onClick={onClickLanguageHandler}>
									{translateMode.lang.displayName ||
										(translateLangs.length && translateLangs[0].displayName)}
									<span className='settings__button-arrow'>{getAppIcon(IcoChevron.pic)}</span>
								</Button>
							</div>
						</div>
					</div>

					<p className='translate-modal__descr'>{modals.translate.descr}</p>
				</div>
				<Button className='btn' onClick={onClickSaveHandler}>
					{modals.translate.btn}
				</Button>
			</div>
		</Modal>
	);
};

export default observer(TranslateModal);
