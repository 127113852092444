import {action, makeObservable, observable} from 'mobx';

class StreamService {
	@observable
	public isScreenSharing = false;

	@observable
	public isLocalAudioTrack = false;

	@observable
	public isMyTalkerShareScreen = false;

	@observable
	public isLayoutSwitched = false;

	@observable
	public isFullScreenVideo = false;

	@observable
	public videoDimensions: number[] | null = null;

	@observable
	public streamAspectRatio = '16:9';

	@observable
	public verticalStreamHeight = 487;

	@observable
	public isFullscreenMessagesShown = true;

	@action
	public setIsScreenSharing = (value: boolean) => {
		this.isScreenSharing = value;
	};

	@action
	public setIsLocalAudioTrack = (value: boolean) => {
		this.isLocalAudioTrack = value;
	};

	@action
	public setIsMyTalkerShareScreen = (value: boolean) => {
		this.isMyTalkerShareScreen = value;
	};

	@action
	public setIsFullScreenVideo = (value: boolean) => {
		this.isFullScreenVideo = value;
	};

	@action
	public setIsLayoutSwitched = (value: boolean) => {
		this.isLayoutSwitched = value;
	};

	@action
	public setVideoDimensions = (value: number[]) => {
		this.videoDimensions = value;
	};

	@action
	public setStreamAspectRatio = (value: string) => {
		this.streamAspectRatio = value;
	};

	@action
	public setIsFullscreenMessagesShown = (value: boolean) => {
		this.isFullscreenMessagesShown = value;
	};

	@action
	public setVerticalStreamHeight = (value: number) => {
		this.verticalStreamHeight = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new StreamService();
