/* eslint-disable max-lines */
import {avatars} from 'data/avatars';
import {icons} from 'data/icons';
import AppAuthStatus from 'models/enums/AppAuthStatus.enum';
import {AppScreens} from 'models/enums/AppScreens.enum';
import LanguageTag from 'models/enums/LanguageTag.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import NetworkQuality from 'models/enums/NetworkQuality.enum';
import AppIconsName from 'models/enums/AppIconsName.enum';
import {AppAvatar, AppIcon, AppTheme} from 'models/app';
import {action, makeObservable, observable, computed} from 'mobx';
import translations from 'l10n/translations';
import {transformationAppIcons} from 'utils/transformationAppIcons';
import {BlockedUsers, User} from 'models/user';
import {SettingsRules} from 'models/settings';
import InterfaceDirection from 'models/enums/InterfaceDirection.enum';
import {CopybettingType} from 'models/enums/CopybettingType.enum';
import OpenProfileType from 'models/enums/OpenProfileType.enum';
import RoomStatus from 'models/enums/RoomStatus.enum';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';
import AppInstance from 'models/enums/AppInstance.enum';
import {VideoPosition} from 'models/enums/VideoPosition.enum';

class AppService {
	@observable
	public project = 'dev';

	@observable
	public appInstance: AppInstance | null = null;

	@observable
	public appEnvironment = 'dev';

	@observable
	public appAuthStatus: AppAuthStatus = AppAuthStatus.PRELOADER;

	@observable
	public appApiUrls: {antimat: string; backend: string} = {
		antimat: '',
		backend: '',
	};

	@observable
	public appReadOnly = false;

	@observable
	public _blockedUsers: User[] = [];

	@observable
	public appVoice = false;

	@observable
	public language: LanguageTag = LanguageTag.en;

	@observable
	public projectLanguage: LanguageTag = LanguageTag.en;

	@observable
	public deletedState = false;

	@observable
	public currentScreen: string | null = null;

	@observable
	public deviceOs: DeviceOs | null = null;

	@observable
	public appVersion: number[] | null = null;

	@observable
	public isInternernetActive = true;

	@observable
	public networkQuality: NetworkQuality = NetworkQuality.NORMAL;

	@observable
	public agoraStatus: AgoraStatus = AgoraStatus.DESTROYED;

	@observable
	public agoraMicrophoneActive: MediaDeviceInfo | null = null;

	@observable
	public agoraMicrophones: MediaDeviceInfo[] = [];

	@observable
	public agoraPlaybackDeviceActive: MediaDeviceInfo | null = null;

	@observable
	public agoraPlaybackDevices: MediaDeviceInfo[] = [];

	@observable
	public rules: SettingsRules = {
		rulesHTML: '',
		policyHTML: '',
		lang: this.language,
	};

	@observable
	public isAppSettings = false;

	@observable
	public feedbackEmail = '';

	@observable
	public appTheme: AppTheme | null = null;

	@observable
	public appIcons: {[key in AppIconsName]: AppIcon} = transformationAppIcons(icons);

	@observable
	public appLoaderIcon = '';

	@observable
	public appAvatars: AppAvatar[] = avatars;

	@observable
	public appExitButtonVisible = true;

	@observable
	public appFullscreenButtonVisible = true;

	@observable
	public appSettingsButtonVisible = true;

	@observable
	public appBettingButtonVisible = true;

	@observable
	public appUserCounterButtonVisible = true;

	@observable
	public appShowAudioQualityCheckPopup = true;

	@observable
	public appEnableAgreementAndRules = false;

	@observable
	public appEnableAvatarChange = false;

	@observable
	public appEnableNameChange = false;

	@observable
	public appEnableOnboarding = false;

	@observable
	public appEnableProfileDeletion = false;

	@observable
	public appEnableUserStatus = false;

	@observable
	public appEnableDecrypt = false;

	@observable
	public appEnableRegistrationName = false;

	@observable
	public appEnableRegistrationPicId = false;

	@observable
	public appEnableRegistrationPicUrl = false;

	@observable
	public appEnableNameVerification = false;

	@observable
	public appNicknameShowed = false;

	@observable
	public appEnableAgora = false;

	@observable
	public appEnableWelcome = false;

	@observable
	public welcomeShowOnce = false;

	@observable
	public welcomeShowFrequency = '';

	@observable
	public welcomeIsDelayed = false;

	@observable
	public welcomeDelay = 0;

	@observable
	public appShowShareButton = false;

	@observable
	public appShowAddToCalendarButton = false;

	@observable
	public appLowerUsersModule = false;

	@observable
	public appShowNameToast = false;

	@observable
	public interfaceDirection = InterfaceDirection.LTR;

	@observable
	public amplitudeApiKey = '';

	@observable
	public tutorialMode = false;

	@observable
	public tutorialModeCompleted = false;

	@observable
	public enableMessagePics = true;

	@observable
	public translateLangs: {
		languageCode: string;
		displayName: string;
	}[] = [];

	@observable
	public shareData: Share = {
		url: '',
		title: '',
		description: '',
	};

	@observable
	public enableRoomSpeak = false;

	@observable
	public isAgoraLoaded = false;

	@observable
	public iosCheckVersionAlertShown = false;

	@observable
	public isWelcomePopupShown = false;

	@observable
	public copybettingType = CopybettingType.BACK_TO_BACK;

	@observable
	public copybettingVersion = 1;

	@observable
	public enableOpenProfile = false;

	@observable
	public openProfileType: OpenProfileType = OpenProfileType.PM;

	@observable
	public showDefaultStickers = true;

	@observable
	public enableLinksBlock = false;

	@observable
	public appEnableMessageTranslation = false;

	@observable
	public appEnableOauth = false;

	@observable
	public enableScreenSharing = false;

	@observable
	public appEnableGamble = false;

	@observable
	public appEnableDomainWhitelist = false;

	@observable
	public appDomainWhitelist = '';

	@observable
	public appShowAnimatedEmotions = true;

	@observable
	public appEnableSubscriptionsFilter = true;

	@observable
	public appEnableThreads = true;

	@observable
	public isStand = false;

	@observable
	public appGambleOpenType: LinkOpenType = LinkOpenType.NEW_WINDOW;

	@observable
	public appEnableWidgetText = false;

	@observable
	public appEnableContactUsButton = true;

	@observable
	public disabledChatText = '';

	@observable
	public roomStatuses: {
		id: number;
		lang: string;
		status: RoomStatus;
		title: string;
		text: string;
	}[] = [];

	@observable
	public videoPosition = '';

	@action
	public setAppAuthStatus = (status: AppAuthStatus) => {
		this.appAuthStatus = status;
	};

	@action
	public setProject = (value: string) => {
		this.project = value;
	};

	@action
	public setAppInstance = (value: AppInstance) => {
		this.appInstance = value;
	};

	@action
	public setAppEnvironment = (value: string) => {
		this.appEnvironment = value;
	};

	@action
	public setAppApiUrls = (data: {antimat?: string; backend?: string}) => {
		this.appApiUrls = {...this.appApiUrls, ...data};
	};

	@action
	public toggleAppReadOnly = (value: boolean) => {
		this.appReadOnly = value;
	};

	@action
	public setRules = (data: SettingsRules) => {
		this.rules = data;
	};

	@action
	public setIsAppSettings = (value: boolean) => {
		this.isAppSettings = value;
	};

	@action
	public setFeedbackEmail = (value: string) => {
		this.feedbackEmail = value;
	};

	@action
	public changeAgoraStatus = (value: AgoraStatus) => {
		this.agoraStatus = value;
	};

	@action
	public setNetworkQuality = (value: NetworkQuality) => {
		if (value === this.networkQuality) return;
		this.networkQuality = value;
	};

	@action
	public changeInternetConnectionStatus = (value: boolean) => {
		this.isInternernetActive = value;
	};

	@action
	public changeCurrentScreen = (value: AppScreens) => {
		this.currentScreen = value;
	};

	@action
	public changeDeleteState = (value: boolean) => {
		this.deletedState = value;
	};

	@action
	public addBlockedUsers = (data: BlockedUsers) => {
		this._blockedUsers = data.initiator;
	};

	@action
	public setAppVoice = (value: boolean) => {
		this.appVoice = value;
	};

	@action
	public setAgoraMicrophoneActive = (data: MediaDeviceInfo) => {
		this.agoraMicrophoneActive = data;
	};

	@action
	public setAgoraMicrophones = (data: MediaDeviceInfo[]) => {
		this.agoraMicrophones = data;
	};

	@action
	public setAgoraPlaybackDeviceActive = (data: MediaDeviceInfo) => {
		this.agoraPlaybackDeviceActive = data;
	};

	@action
	public setAgoraPlaybackDevices = (data: MediaDeviceInfo[]) => {
		this.agoraPlaybackDevices = data;
	};

	@action
	public addBlockedUser = (blockedUser: User) => {
		const findBlockedUser = !!this._blockedUsers.find(item => item.id === blockedUser.id);
		if (!findBlockedUser) {
			this._blockedUsers.push(blockedUser);
		}
	};

	@action
	public removeBlockedUser = (blockedUserId: number) => {
		this._blockedUsers = this._blockedUsers.filter(item => item.id !== blockedUserId);
	};

	@action
	public setLanguage = (lang: LanguageTag) => {
		translations.setLanguage(lang);
		this.language = lang;
	};

	@action
	public setProjectLanguage = (lang: LanguageTag) => {
		this.projectLanguage = lang;
	};

	@action
	public setAppTheme = (theme: AppTheme) => {
		this.appTheme = theme;
	};

	@action
	public setAppIcons = (data: AppIcon[]) => {
		this.appIcons = {...this.appIcons, ...transformationAppIcons(data)};
	};

	@action
	public setAppLoaderIcon = (data: string) => {
		this.appLoaderIcon = data;
	};

	@action
	public setAppAvatars = (data: AppAvatar[]) => {
		this.appAvatars = data;
	};

	@action
	public toggleAppExitButtonVisible = (value: boolean) => {
		this.appExitButtonVisible = value;
	};

	@action
	public toggleAppFullscreenButtonVisible = (value: boolean) => {
		this.appFullscreenButtonVisible = value;
	};

	@action
	public toggleAppSettingsButtonVisible = (value: boolean) => {
		this.appSettingsButtonVisible = value;
	};

	@action
	public toggleAppBettingButtonVisible = (value: boolean) => {
		this.appBettingButtonVisible = value;
	};

	@action
	public toggleAppUserCounterButtonVisible = (value: boolean) => {
		this.appUserCounterButtonVisible = value;
	};

	@action
	public toggleAppShowAudioQualityCheckPopup = (value: boolean) => {
		this.appShowAudioQualityCheckPopup = value;
	};

	@action
	public toggleEnableAppAgreementAndRules = (value: boolean) => {
		this.appEnableAgreementAndRules = value;
	};

	@action
	public toggleEnableAvatarChange = (value: boolean) => {
		this.appEnableAvatarChange = value;
	};

	@action
	public toggleEnableNameChange = (value: boolean) => {
		this.appEnableNameChange = value;
	};

	@action
	public toggleAppEnableOnboarding = (value: boolean) => {
		this.appEnableOnboarding = value;
	};

	@action
	public toggleAppEnableProfileDeletion = (value: boolean) => {
		this.appEnableProfileDeletion = value;
	};

	@action
	public toggleAppEnableUserStatus = (value: boolean) => {
		this.appEnableUserStatus = value;
	};

	@action
	public toggleAppEnableDecrypt = (value: boolean) => {
		this.appEnableDecrypt = value;
	};

	@action
	public toggleAppEnableRegistrationName = (value: boolean) => {
		this.appEnableRegistrationName = value;
	};

	@action
	public toggleAppEnableRegistrationPicId = (value: boolean) => {
		this.appEnableRegistrationPicId = value;
	};

	@action
	public toggleAppEnableRegistrationPicUrl = (value: boolean) => {
		this.appEnableRegistrationPicUrl = value;
	};

	@action
	public toggleAppEnableNameVerification = (value: boolean) => {
		this.appEnableNameVerification = value;
	};

	@action
	public toggleAppNicknameShowed = (value: boolean) => {
		this.appNicknameShowed = value;
	};

	@action
	public toggleAppEnableAgora = (value: boolean) => {
		this.appEnableAgora = value;
	};

	@action
	public toggleAppEnableWelcome = (value: boolean) => {
		this.appEnableWelcome = value;
	};

	@action
	public toggleWelcomeShowOnce = (value: boolean) => {
		this.welcomeShowOnce = value;
	};

	@action
	public setWelcomeShowFrequency = (value: string) => {
		this.welcomeShowFrequency = value;
	};

	@action
	public toggleWelcomeIsDelayed = (value: boolean) => {
		this.welcomeIsDelayed = value;
	};

	@action
	public setWelcomeDelay = (value: number) => {
		this.welcomeDelay = value;
	};

	@action
	public toggleAppShowAddToCalendarButton = (value: boolean) => {
		this.appShowAddToCalendarButton = value;
	};

	@action
	public toggleAppLowerUsersModule = (value: boolean) => {
		this.appLowerUsersModule = value;
	};

	@action
	public toggleAppShowNameToast = (value: boolean) => {
		this.appShowNameToast = value;
	};

	@action
	public toggleAppShowShareButton = (value: boolean) => {
		this.appShowShareButton = value;
	};

	@action
	public setInterfaceDirection = (value: InterfaceDirection) => {
		this.interfaceDirection = value;
	};

	@action
	public setAmplitudeApiKey = (value: string) => {
		this.amplitudeApiKey = value;
	};

	@action
	public toggleTutorialMode = (value: boolean) => {
		this.tutorialMode = value;
	};

	@action
	public toggleTutorialModeCompleted = (value: boolean) => {
		this.tutorialModeCompleted = value;
	};

	@action
	public toggleAppEnableMessagePics = (value: boolean) => {
		this.enableMessagePics = value;
	};

	@action
	public setTranslateLangs = (value: []) => {
		this.translateLangs = value;
	};

	@action
	public setShareData = (data: Share) => {
		this.shareData = data;
	};

	@computed
	get blockedUsers() {
		return this._blockedUsers?.slice();
	}

	@action
	public setDeviceInfo = (deviceOs: DeviceOs | null) => {
		this.deviceOs = deviceOs;
	};

	@action
	public setAppVersion = (value: number[] | null) => {
		this.appVersion = value;
	};

	@action
	public setIsAgoraLoaded = (value: boolean) => {
		this.isAgoraLoaded = value;
	};

	@action
	public setIosCheckVersionAlertShown = (value: boolean) => {
		this.iosCheckVersionAlertShown = value;
	};

	@action
	public setIsWelcomePopupShown = (value: boolean) => {
		this.isWelcomePopupShown = value;
	};

	@action
	public setCopybettingType = (value: CopybettingType) => {
		this.copybettingType = value;
	};

	@action
	public setCopybettingVersion = (value: number) => {
		this.copybettingVersion = value;
	};

	@action
	public setShowDefaultStickers = (value: boolean) => {
		this.showDefaultStickers = value;
	};

	@action
	public setEnableOpenProfile = (value: boolean) => {
		this.enableOpenProfile = value;
	};

	@action
	public setOpenProfileType = (value: OpenProfileType) => {
		this.openProfileType = value;
	};

	@action
	public setEnableLinksBlock = (value: boolean) => {
		this.enableLinksBlock = value;
	};

	@action
	public setEnableMessageTranslation = (value: boolean) => {
		this.appEnableMessageTranslation = value;
	};

	@action
	public setEnableOauth = (value: boolean) => {
		this.appEnableOauth = value;
	};

	@action
	public setEnableScreenSharing = (value: boolean) => {
		this.enableScreenSharing = value;
	};

	@action
	public setEnableRoomSpeak = (value: boolean) => {
		this.enableRoomSpeak = value;
	};

	@action
	public setAppEnableGamble = (value: boolean) => {
		this.appEnableGamble = value;
	};

	@action
	public setAppEnableDomainWhitelist = (value: boolean) => {
		this.appEnableDomainWhitelist = value;
	};

	@action
	public setAppDomainWhitelist = (value: string) => {
		this.appDomainWhitelist = value;
	};

	@action
	public setAppShowAnimatedEmotions = (value: boolean) => {
		this.appShowAnimatedEmotions = value;
	};

	@action
	public setAppEnableSubscriptionFilter = (value: boolean) => {
		this.appEnableSubscriptionsFilter = value;
	};

	@action
	public setAppEnableThreads = (value: boolean) => {
		this.appEnableThreads = value;
	};

	@action
	public setIsStand = (value: boolean) => {
		this.isStand = value;
	};

	@action
	public setaAppGambleOpenType = (value: LinkOpenType) => {
		this.appGambleOpenType = value;
	};

	@action
	public setaAppEnableWidgetText = (value: boolean) => {
		this.appEnableWidgetText = value;
	};

	@action
	public setAppEnableContactUsButton = (value: boolean) => {
		this.appEnableContactUsButton = value;
	};

	@action
	public setDisabledChatText = (value: string) => {
		this.disabledChatText = value;
	};

	@action
	public setRoomStatuses = (
		value: {
			id: number;
			lang: string;
			status: RoomStatus;
			title: string;
			text: string;
		}[]
	) => {
		this.roomStatuses = value;
	};

	@action
	public setVideoPosition = (value: VideoPosition) => {
		this.videoPosition = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new AppService();
