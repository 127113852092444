const useStreamAspectRatio = () => {
	const getAspectRatioClass = (value: string): string => {
		switch (value) {
			case '1:1':
				return 'stream--aspect-ratio-1-1';
			case '16:9':
				return 'stream--aspect-ratio-16-9';
			case '4:3':
				return 'stream--aspect-ratio-4-3';
			case '3:4':
				return 'stream--aspect-ratio-3-4';
			default:
				return '';
		}
	};

	return {getAspectRatioClass};
};

export default useStreamAspectRatio;
