import {MODAL_STYLE} from 'constants/modalOverlay';
import modalServices from 'store/modalService';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {Audio} from 'components/feedback/audio';

const AudioModal: FunctionComponent = function AudioModal() {
	const {feedbackAudioModal} = useLocalObservable(() => modalServices);

	if (!feedbackAudioModal) {
		return null;
	}

	return (
		<Modal
			className='modal modal--feedback-audio'
			isOpen={feedbackAudioModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__body'>
				<Audio />
			</div>
		</Modal>
	);
};

export default observer(AudioModal);
