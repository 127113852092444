/* eslint-disable jsx-a11y/label-has-associated-control */

import {
	MODAL_STYLE,
	MODAL_STYLE_IMAGE_PREVIEW_1,
	MODAL_STYLE_IMAGE_PREVIEW_2,
	MODAL_STYLE_IMAGE_PREVIEW_3,
} from 'constants/modalOverlay';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback} from 'react';
import Modal from 'react-modal';
import modalService from 'store/modalService';
import useL10n from 'l10n/useL10n';
import {textEnding} from 'utils/helpers';
import attachService from 'store/attachService';
import Button from 'components/hoc/Button';
import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import stickersEmojiService from 'store/stickersEmojiService';

import './imagePreview.scss';
import classNames from 'classnames';

const ImagePreviewModal: FunctionComponent = function ImagePreviewModal() {
	const {attach, threads} = useL10n();
	const {appIcons} = useLocalObservable(() => appService);
	const {imagePreviewModalVisible, toggleImagePreviewModalVisible} = useLocalObservable(
		() => modalService
	);
	const {visibleStickersEmoji} = useLocalObservable(() => stickersEmojiService);
	const {files, setFiles, isMessagePreview, setIsMessagePreview, isTyping} = useLocalObservable(
		() => attachService
	);

	const {getAppIcon} = useAppData();
	const {IcoClose, IcoChevron} = appIcons;

	const visiblePics = files.filter(el => el.isVisible || el.file);
	const imagePreviewClasses = classNames('image-preview', {
		'image-preview--message': isMessagePreview,
		'image-preview--typing': isTyping || visibleStickersEmoji,
	});

	const getOverlay = () => {
		if (isMessagePreview) {
			return MODAL_STYLE.overlay;
		}

		// eslint-disable-next-line no-nested-ternary
		return isTyping && !visibleStickersEmoji
			? MODAL_STYLE_IMAGE_PREVIEW_2.overlay
			: visibleStickersEmoji
			? MODAL_STYLE_IMAGE_PREVIEW_3.overlay
			: MODAL_STYLE_IMAGE_PREVIEW_1.overlay;
	};

	const closeClickHandler = () => {
		setFiles([]);
		setIsMessagePreview(false);
		toggleImagePreviewModalVisible(false);
	};

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, fileIndex: number) => {
		const arr = files.map((el, index) => {
			if (index === fileIndex) return {file: el.file, isChecked: e.target.checked};
			return {...el};
		});
		setFiles([...arr]);
	};

	const renderPreview = useCallback(
		(el, index) => {
			return (
				<div key={index} className='image-preview__elem'>
					{el.url ? (
						<img src={el.url} alt='' className='image-preview__img' />
					) : (
						<div className='image-preview__elem-wrapper'>
							<input
								type='checkbox'
								id={`image-preview${index}`}
								checked={el.isChecked}
								onChange={e => onChangeHandler(e, index)}
							/>
							<label htmlFor={`image-preview${index}`}>
								{el.file && (
									<img src={URL.createObjectURL(el.file)} alt='' className='image-preview__img' />
								)}
							</label>
						</div>
					)}
				</div>
			);
		},
		[files]
	);

	const onAfterClose = () => {
		setFiles([]);
	};

	return (
		<Modal
			className='modal modal--image-preview'
			isOpen={imagePreviewModalVisible}
			ariaHideApp={false}
			onAfterClose={onAfterClose}
			style={{
				overlay: getOverlay(),
			}}>
			<div className='modal__head'>
				{isMessagePreview && (
					<Button className='modal__back-btn' onClick={closeClickHandler}>
						<span className='modal__back-btn-arrow'>{getAppIcon(IcoChevron.pic)}</span>
						{threads.back}
					</Button>
				)}
				<div className='modal__title'>
					{visiblePics.length} {textEnding(visiblePics.length, attach.images)}
					{!isMessagePreview && (
						<Button className='modal__close' onClick={closeClickHandler}>
							{getAppIcon(IcoClose.pic)}
						</Button>
					)}
				</div>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className={imagePreviewClasses}>{visiblePics.map(renderPreview)}</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(ImagePreviewModal);
