import {MODAL_STYLE, MODAL_STYLE_STREAM_VIDEO} from 'constants/modalOverlay';
import {useLocalObservable} from 'mobx-react-lite';
import RoomStatus from 'models/enums/RoomStatus.enum';
import {VideoPosition} from 'models/enums/VideoPosition.enum';
import appService from 'store/appService';
import roomService from 'store/roomService';
import streamService from 'store/streamService';

const useModal = () => {
	const {appLowerUsersModule, videoPosition} = useLocalObservable(() => appService);
	const {isScreenSharing, isLayoutSwitched, streamAspectRatio, verticalStreamHeight} =
		useLocalObservable(() => streamService);
	const {roomData} = useLocalObservable(() => roomService);

	const isStreamVideo = !!roomData?.streamUrl && roomData.status === RoomStatus.LIVE;

	const cssVarPanelTopTransparent = getComputedStyle(document.documentElement).getPropertyValue(
		'--panel-top-transparent'
	);

	const getOverlayStyle = () => {
		if (isLayoutSwitched) return {...MODAL_STYLE.overlay, right: 0, left: 'auto'};
		if (isStreamVideo) {
			return {...MODAL_STYLE_STREAM_VIDEO.overlay, zIndex: 10000};
		}
		return MODAL_STYLE.overlay;
	};

	const getOverlayStyleHeight = () => {
		if (isLayoutSwitched || isStreamVideo) return 'unset';

		type AspectRatio = '1:1' | '16:9' | '4:3' | '3:4';
		const height = window.innerHeight;

		const OFFSET_1_1 = 375;
		const OFFSET_16_9 = 211;
		const OFFSET_4_3 = 281;
		const OFFSET_3_4 = verticalStreamHeight;

		const aspectRatioOverMapping: Record<AspectRatio, string> = {
			'1:1': `${height - OFFSET_1_1}px`,
			'16:9': `${height - OFFSET_16_9}px`,
			'4:3': `${height - OFFSET_4_3}px`,
			'3:4': `${height - OFFSET_3_4}px`,
		};

		const over = aspectRatioOverMapping[streamAspectRatio as AspectRatio] || `${height - 211}px`;
		const headerTransparent = cssVarPanelTopTransparent && JSON.parse(cssVarPanelTopTransparent);
		const headerOffset = headerTransparent ? 0 : 44;
		const appLower = `calc(${over} - ${headerOffset}px)`;

		if (appLowerUsersModule) {
			if (isScreenSharing && videoPosition !== VideoPosition.FLOATING) {
				return appLower;
			}
			return videoPosition === VideoPosition.FLOATING || !isScreenSharing
				? `${height - headerOffset}px`
				: 'unset';
		}

		if (isScreenSharing && videoPosition === VideoPosition.OVER) {
			return over;
		}

		if (isScreenSharing && videoPosition === VideoPosition.UNDER) {
			return appLower;
		}

		return '100%';
	};

	const getOverlayStyleWidth = () => {
		if (isLayoutSwitched) {
			return '480px';
		}
		return '100%';
	};

	return {getOverlayStyle, getOverlayStyleWidth, getOverlayStyleHeight};
};

export default useModal;
