/* eslint-disable jsx-a11y/label-has-associated-control */
import {MODAL_STYLE_CENTER} from 'constants/modalOverlay';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import Modal from 'react-modal';
import modalService from 'store/modalService';
import './streamSettingsModal.scss';
import useL10n from 'l10n/useL10n';
import {StreamType} from 'models/enums/StreamType.enum';
import Button from 'components/hoc/Button';
import useAgora from 'hooks/useAgora';
import appService from 'store/appService';
import useAppData from 'hooks/useAppData';
import roomService from 'store/roomService';
import userService from 'store/userService';
import toastService from 'store/toastService';
import {ToastIconsName} from 'components/toasts/ToastIcons';

const AgoraServerURLs = {
	DEFAULT: 'rtmp://rtls-ingress-prod-eu.agoramdn.com/live',
	US: 'rtmp://rtls-ingress-prod-na.agoramdn.com/live',
	HK: 'rtmp://rtls-ingress-prod-ap.agoramdn.com/live',
};

const StreamSettingsModal: FunctionComponent = function StreamSettingsModal() {
	const {modals, toasts} = useL10n();
	const [streamType, setStreamType] = useState(StreamType.SCREEN_SHARING);
	const [step, setStep] = useState(1);
	const [streamKey, setStreamKey] = useState('');
	const [inputType, setInputType] = useState('password');
	const [serverUrl, setServerUrl] = useState('');
	const [visibleLoader, setVisibleLoader] = useState(false);
	const {streamSettingsModalVisible, toggleStreamSettingsModalVisible} = useLocalObservable(
		() => modalService
	);
	const {appIcons, appLoaderIcon} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomService);
	const {userData} = useLocalObservable(() => userService);
	const {addToast} = useLocalObservable(() => toastService);

	const {getAppIcon} = useAppData();
	const {initScreenShareTrack, getStreamKey} = useAgora();
	const {IcoChevron, IcoCopy, IcoToastMessageHidden, IcoToastMessageShown} = appIcons;

	const clear = () => {
		setStreamKey('');
		setInputType('password');
		setStep(1);
	};
	const initScreenShare = () => {
		initScreenShareTrack();
		toggleStreamSettingsModalVisible(false);
	};

	const handleStreamSettings = async () => {
		if (streamType === StreamType.SCREEN_SHARING) {
			initScreenShare();
			return;
		}
		setStep(2);
		if (roomId && userData?.externalId) {
			setVisibleLoader(true);
			const response = await getStreamKey({
				externalRoomId: roomId,
				externalUserId: userData.externalId,
			});
			if (response) {
				setStreamKey(response);
				setVisibleLoader(false);
			}
		}
	};

	const handleCopy = (value: string) => {
		navigator.clipboard
			.writeText(value)
			.then(() => {
				addToast({
					iconName: ToastIconsName.copy,
					liveTime: 3000,
					message: toasts.streamKeyCopied,
					cancellable: true,
				});
			})
			.catch(error => {
				addToast({
					iconName: ToastIconsName.warning,
					liveTime: 3000,
					message: 'error',
					cancellable: true,
				});
			});
	};

	const getAgoraServerLink = (url: string) => {
		if (url.includes('chat.us.watchers')) {
			return AgoraServerURLs.US;
		}
		if (url.includes('chat.hk.watchers')) {
			return AgoraServerURLs.HK;
		}
		return AgoraServerURLs.DEFAULT;
	};

	useEffect(() => {
		setServerUrl(getAgoraServerLink(window.location.href));
	}, []);

	return (
		<Modal
			className='modal modal--stream-settings'
			isOpen={streamSettingsModalVisible}
			onAfterClose={clear}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE_CENTER.overlay,
			}}>
			<section className='streamSettings-modal'>
				<div className='streamSettings-modal__head'>
					<p className='streamSettings-modal__title'>{modals.streamSettings.title}</p>
					{step === 2 && (
						<button type='button' className='streamSettings-modal__back' onClick={() => setStep(1)}>
							{getAppIcon(IcoChevron.pic)}
						</button>
					)}
				</div>
				{step === 1 && (
					<>
						<div className='streamSettings-modal__body'>
							<div className='streamSettings-modal__radio-group'>
								<div className='streamSettings-modal__radio-item'>
									<input
										type='radio'
										id='streamType1'
										name='streamType'
										value={StreamType.SCREEN_SHARING}
										onChange={() => setStreamType(StreamType.SCREEN_SHARING)}
										checked={streamType === StreamType.SCREEN_SHARING}
										className='streamSettings-modal__radio-input'
									/>
									<label className='streamSettings-modal__radio-label' htmlFor='streamType1'>
										<p className='streamSettings-modal__radio-title'>
											{modals.streamSettings.shareScreen.title}
										</p>
										<p className='streamSettings-modal__radio-text'>
											{modals.streamSettings.shareScreen.description}
										</p>
									</label>
								</div>
								<div className='streamSettings-modal__radio-item'>
									<input
										type='radio'
										id='streamType2'
										name='streamType'
										value={StreamType.RTMP}
										onChange={() => setStreamType(StreamType.RTMP)}
										checked={streamType === StreamType.RTMP}
										className='streamSettings-modal__radio-input'
									/>
									<label className='streamSettings-modal__radio-label' htmlFor='streamType2'>
										<p className='streamSettings-modal__radio-title'>
											{modals.streamSettings.rtmp.title}
										</p>
										<p className='streamSettings-modal__radio-text'>
											{modals.streamSettings.rtmp.description}
										</p>
									</label>
								</div>
							</div>
						</div>
						<div className='streamSettings-modal__footer'>
							<Button className='btn' onClick={handleStreamSettings}>
								{modals.streamSettings.btns.continue}
							</Button>
							<Button
								className='btn btn--text'
								onClick={() => toggleStreamSettingsModalVisible(false)}>
								{modals.streamSettings.btns.close}
							</Button>
						</div>
					</>
				)}
				{step === 2 && visibleLoader && (
					<div className='streamSettings-modal__loader'>{getAppIcon(appLoaderIcon)}</div>
				)}
				{step === 2 && !visibleLoader && (
					<>
						<div className='streamSettings-modal__body'>
							<p className='streamSettings-modal__description'>
								{modals.streamSettings.description}
							</p>
							<div className='streamSettings-modal__input-wrapper'>
								<input
									type={inputType}
									value={streamKey}
									id='streamKey'
									disabled
									className='streamSettings-modal__input-control'
								/>
								<button
									type='button'
									onClick={() => handleCopy(streamKey)}
									className='streamSettings-modal__copy-btn'>
									{getAppIcon(IcoCopy.pic)}
								</button>

								<label htmlFor='streamKey' className='streamSettings-modal__input-label'>
									{modals.streamSettings.streamKey}
								</label>
							</div>
							<div className='streamSettings-modal__input-wrapper'>
								<input
									type='text'
									value={serverUrl}
									id='serverUrl'
									disabled
									className='streamSettings-modal__input-control'
								/>
								<button
									type='button'
									onClick={() => handleCopy(serverUrl)}
									className='streamSettings-modal__copy-btn'>
									{getAppIcon(IcoCopy.pic)}
								</button>

								<label htmlFor='serverUrl' className='streamSettings-modal__input-label'>
									Server
								</label>
							</div>
							<div>
								<button
									type='button'
									className='streamSettings-modal__visibility-btn'
									onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}>
									{inputType === 'password'
										? getAppIcon(IcoToastMessageHidden.pic)
										: getAppIcon(IcoToastMessageShown.pic)}
									{inputType === 'password'
										? modals.streamSettings.showStreamKey
										: modals.streamSettings.hideStreamKey}
								</button>
							</div>
						</div>

						<div className='streamSettings-modal__footer'>
							<Button className='btn' onClick={() => toggleStreamSettingsModalVisible(false)}>
								{modals.streamSettings.btns.ok}
							</Button>
						</div>
					</>
				)}
			</section>
		</Modal>
	);
};

export default observer(StreamSettingsModal);
