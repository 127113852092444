import classNames from 'classnames';
import useAgora from 'hooks/useAgora';
import {observer, useLocalObservable} from 'mobx-react-lite';
import UserRole from 'models/enums/UserRole.enum';
import {FunctionComponent, useEffect, useState} from 'react';
import roomService from 'store/roomService';
import streamService from 'store/streamService';
import userService from 'store/userService';
import useL10n from 'l10n/useL10n';
import threadService from 'store/threadService';
import {isDesktop} from 'react-device-detect';
import usePlayer from 'hooks/usePlayer';
import appService from 'store/appService';
import {VideoPosition} from 'models/enums/VideoPosition.enum';
import modalService from 'store/modalService';
import SocketIoServices from 'services/SocketIoServices';
import useFullscreen from 'hooks/useFullscreen';

const ChatVideoTrack: FunctionComponent = function ChatVideoTrack() {
	const [aspectRatioClass, setAspectRatioClass] = useState('');

	const {videoPosition, setVideoPosition} = useLocalObservable(() => appService);
	const {myTalker, pinnedMessages} = useLocalObservable(() => roomService);
	const {userData} = useLocalObservable(() => userService);
	const {
		isMyTalkerShareScreen,
		isLayoutSwitched,
		isFullScreenVideo,
		setIsFullScreenVideo,
		setIsLayoutSwitched,
		videoDimensions,
		isScreenSharing,
		setStreamAspectRatio,
		streamAspectRatio,
		isFullscreenMessagesShown,
		setIsFullscreenMessagesShown,
		verticalStreamHeight,
	} = useLocalObservable(() => streamService);
	const {currentThreadId, setCurrentThreadId, clearTread} = useLocalObservable(() => threadService);
	const {toggleVisibleThreadModal} = useLocalObservable(() => modalService);
	const {stopScreenShareTrack} = useAgora();
	const {screenShare} = useL10n();
	const {videoPostMessage} = usePlayer();
	const {enterFullscreen, exitFullscreen} = useFullscreen();

	const myTalkerIsModer = !!myTalker?.isModer || !!userData?.isModer;
	const myTalkerSpeaker = myTalker?.role === UserRole.SPEAKER || myTalkerIsModer;

	type AspectRatio = '1:1' | '16:9' | '4:3' | '3:4';

	const getVideoAspectRatio = (width: number, height: number): AspectRatio => {
		if (width <= 0 || height <= 0) {
			throw new Error('Width and height must be positive numbers.');
		}
		const currentRatio = width / height;

		const aspectRatios: Record<AspectRatio, number> = {
			'1:1': 1,
			'16:9': 16 / 9,
			'4:3': 4 / 3,
			'3:4': 3 / 4,
		};

		let closestRatio: AspectRatio = '1:1';
		let minDifference = Infinity;

		// eslint-disable-next-line no-restricted-syntax
		for (const [ratioName, ratioValue] of Object.entries(aspectRatios) as [AspectRatio, number][]) {
			const difference = Math.abs(currentRatio - ratioValue);
			if (difference < minDifference) {
				minDifference = difference;
				closestRatio = ratioName;
			}
		}

		setStreamAspectRatio(closestRatio);
		return closestRatio;
	};

	const getAspectRatioClass = (width: number, height: number): string => {
		const aspectRatio = getVideoAspectRatio(width, height);

		switch (aspectRatio) {
			case '1:1':
				return 'chat__video-track--aspect-ratio-1-1';
			case '16:9':
				return 'chat__video-track--aspect-ratio-16-9';
			case '4:3':
				return 'chat__video-track--aspect-ratio-4-3';
			case '3:4':
				return 'chat__video-track--aspect-ratio-3-4';
			default:
				return '';
		}
	};

	const videoTrackClasses = classNames('chat__video-track', {
		'chat__video-track--switched-layout': isLayoutSwitched,
		'chat__video-track--speaker': isMyTalkerShareScreen,
		'chat__video-track--pinned': pinnedMessages?.length,
		'chat__video-track--thread-opened': currentThreadId && !isLayoutSwitched,
		'chat__video-track--fullscreen': isFullScreenVideo,
		[`chat__video-track--${videoPosition?.toLowerCase()}`]:
			!isMyTalkerShareScreen && !!videoPosition,
		[aspectRatioClass]: aspectRatioClass,
	});

	const messagesButtonClasses = classNames('chat__messages-btn', {
		'chat__messages-btn--show': !isFullscreenMessagesShown,
		'chat__messages-btn--hide': isFullscreenMessagesShown,
	});

	const checkAndCloseThread = () => {
		if (currentThreadId) {
			setCurrentThreadId(null);
			toggleVisibleThreadModal(false);
			clearTread();
			if (currentThreadId && currentThreadId !== 'new') SocketIoServices.emitLeave(currentThreadId);
		}
	};
	const chatVisibilityHandler = () => {
		setIsFullscreenMessagesShown(!isFullscreenMessagesShown);
	};

	const fullScreenHandler = () => {
		checkAndCloseThread();

		if (isDesktop) {
			if (isFullScreenVideo) {
				exitFullscreen();
			} else enterFullscreen();
		}

		setIsFullScreenVideo(!isFullScreenVideo);
		videoPostMessage(isFullScreenVideo ? 'FULLSCREEN_DISABLED' : 'FULLSCREEN_ENABLED');
	};

	const stopScreenShareHandler = () => {
		stopScreenShareTrack();
	};

	const getScreenSize = () => {
		if (window.innerHeight < 520) {
			setVideoPosition(VideoPosition.FLOATING);
		}
	};

	useEffect(() => {
		if (videoDimensions) {
			if (videoDimensions[0] > 0 && videoDimensions[1] > 0)
				setAspectRatioClass(getAspectRatioClass(videoDimensions[0], videoDimensions[1]));
		}
	}, [videoDimensions]);

	useEffect(() => {
		if (window.innerWidth > 800 && isDesktop && isScreenSharing) setIsLayoutSwitched(true);
		getScreenSize();
	}, []);

	return (
		<div
			className={videoTrackClasses}
			style={
				videoPosition !== VideoPosition.FLOATING && streamAspectRatio === '3:4' && !isLayoutSwitched
					? {height: `${verticalStreamHeight}px`}
					: undefined
			}>
			{!isMyTalkerShareScreen && (
				<>
					{isFullScreenVideo && (
						<button type='button' className={messagesButtonClasses} onClick={chatVisibilityHandler}>
							{' '}
						</button>
					)}
					<button type='button' className='chat__fullscreen-btn' onClick={fullScreenHandler}>
						{' '}
					</button>
				</>
			)}
			{myTalkerSpeaker && isMyTalkerShareScreen && (
				<button type='button' className='chat__stop-sharing-btn' onClick={stopScreenShareHandler}>
					{screenShare.stopSharing}
				</button>
			)}
		</div>
	);
};

export default observer(ChatVideoTrack);
