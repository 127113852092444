import {useLocalObservable} from 'mobx-react-lite';
import {useEffect, useCallback} from 'react';
import streamService from 'store/streamService';
import usePlayer from './usePlayer';

const useFullscreen = () => {
	const {setIsFullScreenVideo} = useLocalObservable(() => streamService);
	const {videoPostMessage} = usePlayer();
	const handleFullscreenChange = useCallback(() => {
		const fullscreenElement =
			document.fullscreenElement ||
			(document as any).webkitFullscreenElement ||
			(document as any).mozFullScreenElement ||
			(document as any).msFullscreenElement;

		setIsFullScreenVideo(!!fullscreenElement);
		videoPostMessage(!fullscreenElement ? 'FULLSCREEN_DISABLED' : 'FULLSCREEN_ENABLED');
	}, []);

	const enterFullscreen = useCallback((element: HTMLElement = document.documentElement) => {
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if ((element as any).webkitRequestFullscreen) {
			(element as any).webkitRequestFullscreen(); // Safari
		} else if ((element as any).mozRequestFullScreen) {
			(element as any).mozRequestFullScreen(); // Firefox
		} else if ((element as any).msRequestFullscreen) {
			(element as any).msRequestFullscreen(); // IE/Edge
		} else {
			console.error('Fullscreen API is not supported');
		}
	}, []);

	const exitFullscreen = useCallback(() => {
		if (
			document.fullscreenElement ||
			(document as any).webkitFullscreenElement ||
			(document as any).mozFullScreenElement ||
			(document as any).msFullscreenElement
		) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if ((document as any).webkitExitFullscreen) {
				(document as any).webkitExitFullscreen(); // Safari
			} else if ((document as any).mozCancelFullScreen) {
				(document as any).mozCancelFullScreen(); // Firefox
			} else if ((document as any).msExitFullscreen) {
				(document as any).msExitFullscreen(); // IE/Edge
			} else {
				console.error('Fullscreen API is not supported');
			}
		} else {
			console.log('Документ не находится в режиме fullscreen');
		}
	}, []);

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullscreenChange);
		document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Safari
		document.addEventListener('mozfullscreenchange', handleFullscreenChange); // Firefox
		document.addEventListener('MSFullscreenChange', handleFullscreenChange); // IE/Edge

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
			document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
			document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
			document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
		};
	}, [handleFullscreenChange]);

	return {
		enterFullscreen,
		exitFullscreen,
	};
};

export default useFullscreen;
