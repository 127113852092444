import {MODAL_STYLE} from 'constants/modalOverlay';
import modalServices from 'store/modalService';
import useAppData from 'hooks/useAppData';
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {
	ProfileMainScreen,
	ProfileEditNameScreen,
	ProfileEditAvatarScreen,
} from 'components/profileScreens/index';
import {AppScreens} from 'models/enums/AppScreens.enum';
import appService from 'store/appService';
import Button from 'components/hoc/Button';
import useModal from 'hooks/useModal';

interface IProfileModalProps {
	startScreen?: string;
}

const ProfileModal: React.FC<IProfileModalProps> = function ProfileModal({startScreen}) {
	const [currentScreen, setCurrentScreen] = React.useState(startScreen || 'name');
	const {profileModalVisible, hideProfileModal} = useLocalObservable(() => modalServices);
	const {changeCurrentScreen, appIcons} = useLocalObservable(() => appService);

	const {getAppIcon} = useAppData();
	const {IcoClose} = appIcons;
	const {getOverlayStyle, getOverlayStyleWidth, getOverlayStyleHeight} = useModal();

	const closeModal = () => {
		hideProfileModal();
		setCurrentScreen('main');
	};

	// eslint-disable-next-line consistent-return
	const renderPage = () => {
		switch (currentScreen) {
			case 'main':
				return <ProfileMainScreen setCurrentScreen={setCurrentScreen} />;
			case 'name':
				return <ProfileEditNameScreen setCurrentScreen={() => setCurrentScreen('main')} />;
			case 'avatar':
				return <ProfileEditAvatarScreen setCurrentScreen={() => setCurrentScreen('main')} />;
			default:
				break;
		}
	};

	useEffect(() => {
		profileModalVisible
			? changeCurrentScreen(AppScreens.PROFILE)
			: changeCurrentScreen(AppScreens.SETTINGS);
	}, [profileModalVisible]);

	useEffect(() => {
		switch (currentScreen) {
			case 'main':
				changeCurrentScreen(AppScreens.PROFILE);
				break;
			case 'name':
				changeCurrentScreen(AppScreens.NAME);
				break;
			case 'avatar':
				changeCurrentScreen(AppScreens.AVATAR);
				break;
			default:
				break;
		}
	}, [currentScreen]);

	if (!profileModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal modal--profile'
			isOpen={profileModalVisible}
			ariaHideApp={false}
			style={{
				overlay: getOverlayStyle(),
				content: {
					width: getOverlayStyleWidth(),
					height: getOverlayStyleHeight(),
				},
			}}>
			<div className='modal__head'>
				<Button className='modal__close' onClick={closeModal}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>{renderPage()}</div>
			</div>
		</Modal>
	);
};

export default observer(ProfileModal);
