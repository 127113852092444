import UserRole from 'models/enums/UserRole.enum';
import LanguageTag from 'models/enums/LanguageTag.enum';
import {Talker, FilterUsersBtn} from 'models/room';
import {MODAL_STYLE} from 'constants/modalOverlay';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';

import useAppData from 'hooks/useAppData';
import useModal from 'hooks/useModal';

import {CSSTransition} from 'react-transition-group';
import {useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import debounce from 'lodash/debounce';
import appService from 'store/appService';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useTalkers from 'hooks/useTalkers';
import {AppScreens} from 'models/enums/AppScreens.enum';
import Button from 'components/hoc/Button';
import './usersModal.scss';
import UserItem from './UserItem';
import UsersModalPreloader from './UsersModalPreloader';
import UsersModalSkeleton from './UsersModalSkeleton';

const UsersModal: React.FC = function UsersModal() {
	const [activeFilterBtn, setActiveFilterBtn] = useState(UserRole.ALL);
	const [visiblePreloaderBottom, setVisiblePreloaderBottom] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [currentTalkers, setCurrentTalkers] = useState<Talker[]>([]);

	const {roomData, talkers, setTalkers, speakers, subscriptions} = useLocalObservable(
		() => roomServices
	);

	const {
		appVoice,
		changeCurrentScreen,
		language,
		appIcons,
		enableRoomSpeak,
		appEnableSubscriptionsFilter,
	} = useLocalObservable(() => appService);
	const {visibleModalUsers, hideVisibleModalUsers} = useLocalObservable(() => modalServices);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();
	const {getTalkers, getNextTalkers, getSpeakers} = useTalkers();
	const {getOverlayStyle, getOverlayStyleWidth, getOverlayStyleHeight} = useModal();

	const talkersScrollRef = useRef<HTMLDivElement>(null);

	const {IcoClose, IcoMessage, IcoSpeaker, IcoUsers} = appIcons;

	const isFilterUsersBtns = (speakers.length > 0 && appVoice) || appEnableSubscriptionsFilter;

	const filterUsersBtnsData: FilterUsersBtn[] = [
		{
			id: 0,
			title: {
				ru: 'Все',
				en: 'All',
			},
			icon: getAppIcon(IcoMessage.pic),
			role: UserRole.ALL,
		},
		{
			id: 1,
			title: {
				ru: 'Спикеры',
				en: 'Speakers',
			},
			icon: getAppIcon(IcoSpeaker.pic),
			role: UserRole.SPEAKER,
		},
		{
			id: 2,
			title: {
				ru: 'Подписки',
				en: 'Following',
			},
			icon: getAppIcon(IcoUsers.pic),
			role: UserRole.SUBSCRIPTION,
		},
	];

	const getFilterUsersBtns = () => {
		let btns = [...filterUsersBtnsData];
		if (!appVoice || (appVoice && !speakers.length)) {
			btns = btns.filter(el => el.role !== UserRole.SPEAKER);
		}
		if (!appEnableSubscriptionsFilter || !subscriptions.length) {
			btns = btns.filter(el => el.role !== UserRole.SUBSCRIPTION);
		}
		if (btns.length === 1) return [];
		return btns;
	};

	const onFilterHandler = (btn: FilterUsersBtn) => setActiveFilterBtn(btn.role);

	const onCloseModalHandler = () => {
		sendAnalytics('user-list_closed');
		hideVisibleModalUsers();
		setActiveFilterBtn(UserRole.ALL);
	};

	const renderFilterBtn = useCallback(
		(btn: FilterUsersBtn) => (
			<div className='users-modal__filter-item' key={btn.id}>
				<Button
					className={`users-modal__filter-btn ${btn.role === activeFilterBtn ? 'active' : ''}`}
					onClick={() => onFilterHandler(btn)}>
					{btn.icon}
					{language === LanguageTag.ru ? btn.title.ru : btn.title.en}
				</Button>
			</div>
		),
		[currentTalkers, activeFilterBtn]
	);

	const renderUser = (item: Talker, key: number | string) => <UserItem key={key} talker={item} />;

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				getNextTalkers(scrollEvent, talkersScrollRef.current, setVisiblePreloaderBottom),
			50
		),
		[currentTalkers, visiblePreloader, visiblePreloaderBottom, activeFilterBtn]
	);

	const getTalkersWithServices = async () => {
		setVisiblePreloader(true);
		await getTalkers();
		if (appVoice) await getSpeakers();
		setVisiblePreloader(false);
	};

	const getFilteredTalkers = () => {
		if (activeFilterBtn === UserRole.ALL) {
			setCurrentTalkers(talkers);
			return;
		}
		if (activeFilterBtn === UserRole.SUBSCRIPTION) {
			setCurrentTalkers(subscriptions);
			return;
		}
		if (activeFilterBtn === UserRole.SPEAKER) {
			setCurrentTalkers(speakers);
		}
	};

	useEffect(() => {
		getFilteredTalkers();
	}, [activeFilterBtn]);

	useEffect(() => {
		visibleModalUsers
			? changeCurrentScreen(AppScreens.USERS)
			: changeCurrentScreen(AppScreens.CHAT);
	}, [visibleModalUsers]);

	useEffect(() => {
		if (!roomData?.isSpeak && !enableRoomSpeak && activeFilterBtn !== UserRole.ALL) {
			setActiveFilterBtn(UserRole.ALL);
		}
	}, [roomData?.isSpeak, enableRoomSpeak]);

	useEffect(() => {
		if (activeFilterBtn === UserRole.ALL) setCurrentTalkers(talkers);
	}, [talkers]);

	useEffect(() => {
		if (activeFilterBtn === UserRole.SPEAKER) {
			setCurrentTalkers(speakers);
		}
	}, [speakers]);

	useEffect(() => {
		if (visibleModalUsers) getTalkersWithServices();
		return () => {
			setTalkers([]);
			setCurrentTalkers([]);
		};
	}, [visibleModalUsers]);

	if (!visibleModalUsers) {
		return null;
	}

	return (
		<Modal
			className='modal'
			isOpen={visibleModalUsers}
			ariaHideApp={false}
			style={{
				overlay: getOverlayStyle(),
				content: {
					width: getOverlayStyleWidth(),
					height: getOverlayStyleHeight(),
				},
			}}>
			<section className='users-modal'>
				<p className='users-modal__title'>{translations.chatUsersScreen.title}</p>
				<Button className='modal__close' onClick={onCloseModalHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>

				{isFilterUsersBtns && !!getFilterUsersBtns().length && (
					<div className='users-modal__filter'>{getFilterUsersBtns().map(renderFilterBtn)}</div>
				)}
				<div className='users-modal__body'>
					<UsersModalSkeleton visible={visiblePreloader} />

					<CSSTransition in={!visiblePreloader} timeout={300} classNames='skeleton' unmountOnExit>
						<div className='users-modal__axis-y' onScroll={onScrollHandler} ref={talkersScrollRef}>
							<div className='users-modal__users-list'>
								{currentTalkers.map(renderUser)}
								{activeFilterBtn !== UserRole.SPEAKER && (
									<UsersModalPreloader visible={visiblePreloaderBottom} />
								)}
							</div>
						</div>
					</CSSTransition>
				</div>
			</section>
		</Modal>
	);
};

export default observer(UsersModal);
