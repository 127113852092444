export const MODAL_STYLE = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 9999,
	},
};

export const MODAL_STYLE_CENTER = {
	overlay: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		zIndex: 1000,
	},
};

export const MODAL_STYLE_IMAGE_PREVIEW_1 = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
		bottom: '50px',
		top: 'auto',
		height: '340px',
	},
};

export const MODAL_STYLE_IMAGE_PREVIEW_2 = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
		bottom: '50px',
		top: 'auto',
		height: '150px',
	},
};

export const MODAL_STYLE_IMAGE_PREVIEW_3 = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
		bottom: '310px',
		top: 'auto',
		height: '150px',
	},
};

export const MODAL_STYLE_BET_PREVIEW = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
		bottom: '50px',
		top: 'auto',
		height: '60px',
	},
};

export const MODAL_STYLE_GAMBLE_PREVIEW = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
		bottom: '50px',
		top: 'auto',
		height: '98px',
	},
};

export const MODAL_STYLE_STREAM_VIDEO = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 400,
		top: '56vw',
		bottom: 0,
		height: 'calc(100% - 56vw)',
	},
};
