import {observer, useLocalObservable} from 'mobx-react-lite';
import {Message} from 'models/room';
import {FunctionComponent} from 'react';
import modalService from 'store/modalService';
import threadService from 'store/threadService';
import useAppData from 'hooks/useAppData';
import appService from 'store/appService';
import {textEnding} from 'utils/helpers';
import useL10n from 'l10n/useL10n';
import SocketIoServices from 'services/SocketIoServices';
import roomService from 'store/roomService';
import actionMessageService from 'store/actionMessageService';
import streamService from 'store/streamService';

interface IChatMessageThreadButton {
	message: Message;
	isThread?: boolean;
}

const ChatMessageThreadButton: FunctionComponent<IChatMessageThreadButton> =
	function ChatMessageThreadButton({message, isThread}) {
		const {threads} = useL10n();
		const {thread, threadMessagesCount} = message;
		const {appIcons, language, appEnableThreads} = useLocalObservable(() => appService);
		const {setCurrentThreadId} = useLocalObservable(() => threadService);
		const {toggleVisibleThreadModal} = useLocalObservable(() => modalService);
		const {submenuMessage, setSubmenuMessage} = useLocalObservable(() => roomService);
		const {isVisibleActionMessage, toggleIsVisibleActionMessage} = useLocalObservable(
			() => actionMessageService
		);
		const {isFullScreenVideo, setIsFullScreenVideo} = useLocalObservable(() => streamService);
		const {getAppIcon} = useAppData();
		const {IcoChevron} = appIcons;

		const replyInThreadClickHandler = () => {
			if (isFullScreenVideo) setIsFullScreenVideo(false);
			if (submenuMessage) setSubmenuMessage(null);
			if (isVisibleActionMessage) toggleIsVisibleActionMessage(false);
			if (thread?.externalRoomId && thread?.name) {
				SocketIoServices.emitJoin(thread?.externalRoomId, language, thread?.name);
				toggleVisibleThreadModal(true);
				setCurrentThreadId(thread?.externalRoomId);
			}
		};

		if (!threadMessagesCount || isThread || !appEnableThreads) return null;
		return (
			<button
				type='button'
				onClick={replyInThreadClickHandler}
				className='chat__message-thread-count'>
				{threadMessagesCount} {textEnding(threadMessagesCount, threads.replies)}
				<span className='chat__message-thread-arrow'>{getAppIcon(IcoChevron.pic)}</span>
			</button>
		);
	};

export default observer(ChatMessageThreadButton);
