/* eslint-disable react/no-danger */
import {MODAL_STYLE} from 'constants/modalOverlay';
import appService from 'store/appService';
import modalServices from 'store/modalService';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import {FunctionComponent} from 'react';
import Modal from 'react-modal';
import {observer, useLocalObservable} from 'mobx-react-lite';
import useL10n from 'l10n/useL10n';
import Button from 'components/hoc/Button';

const RulesModal: FunctionComponent = function RulesModal() {
	const {rulesModalVisible, toggleRulesModal} = useLocalObservable(() => modalServices);
	const {rules, appIcons} = useLocalObservable(() => appService);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const {getAppIcon} = useAppData();

	const {IcoClose} = appIcons;

	const closeModalHandler = () => {
		sendAnalytics('rules_closed');
		toggleRulesModal(false);
	};

	if (!rulesModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal'
			isOpen={rulesModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{translations.rulesScreen.title}</div>
				<Button className='modal__close' onClick={closeModalHandler}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='modal__rules-text' dangerouslySetInnerHTML={{__html: rules.rulesHTML}} />
				</div>
			</div>
		</Modal>
	);
};

export default observer(RulesModal);
