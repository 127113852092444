import {MODAL_STYLE} from 'constants/modalOverlay';
import appService from 'store/appService';
import modalService from 'store/modalService';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import classNames from 'classnames';
import {Recording} from 'components/recording';
import Button from 'components/hoc/Button';
import useAnalytics from 'hooks/useAnalytics';
import roomService from 'store/roomService';

const RecordingModal: FunctionComponent = function RecordingModal() {
	const {appIcons} = useLocalObservable(() => appService);
	const {recordingModalVisible} = useLocalObservable(() => modalService);
	const {roomData} = useLocalObservable(() => roomService);
	const {getAppIcon} = useAppData();

	const [isViewSmall, setIsViewSmall] = useState(false);

	const {IcoChevron} = appIcons;

	const {sendAnalytics} = useAnalytics();

	const modalClasses = classNames('modal modal--record', {
		'modal--record-min': isViewSmall,
		'modal--record-video': roomData?.isRecordVideo,
	});

	const onToggleViewModalHandler = () => {
		setIsViewSmall(!isViewSmall);
	};

	useEffect(() => {
		recordingModalVisible &&
			sendAnalytics('records_player', {
				view: isViewSmall ? 'minimized' : 'expanded',
			});
	}, [isViewSmall, recordingModalVisible]);

	if (!recordingModalVisible) {
		return null;
	}

	return (
		<Modal
			className={modalClasses}
			overlayClassName='ReactModal__Overlay--min'
			isOpen={recordingModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<Button className='modal__toggle-view' onClick={onToggleViewModalHandler}>
				{getAppIcon(IcoChevron.pic)}
			</Button>
			<Recording isViewSmall={isViewSmall} />
		</Modal>
	);
};

export default observer(RecordingModal);
