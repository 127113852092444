import {MODAL_STYLE} from 'constants/modalOverlay';
import appService from 'store/appService';
import modalServices from 'store/modalService';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import Button from 'components/hoc/Button';
import {Switcher} from 'components/switcher';
import {useCallback} from 'react';
import debounce from 'lodash/debounce';
import useAnalytics from 'hooks/useAnalytics';
import userService from 'store/userService';
import useUser from 'hooks/useUser';
import useTranslation from 'hooks/useTranslation';
import useModal from 'hooks/useModal';

const LanguageModal: React.FC = function LanguageModal() {
	const {languageScreen} = useL10n();
	const {appIcons, translateLangs} = useLocalObservable(() => appService);
	const {translateMode, setTranslateMode, userData} = useLocalObservable(() => userService);
	const {languageModalVisible, toggleLanguageModal, toggleLanguagesModalVisible} =
		useLocalObservable(() => modalServices);

	const {getAppIcon} = useAppData();
	const {sendAnalytics} = useAnalytics();
	const {userExtraDataPatchTranslateMode} = useUser();
	const {translateMessages} = useTranslation();
	const {IcoClose, IcoChevron} = appIcons;
	const {getOverlayStyle, getOverlayStyleWidth, getOverlayStyleHeight} = useModal();

	const onChangeTranslateSwitcherHandler = useCallback(
		debounce(
			() => {
				sendAnalytics('autotranslation_toggle', {
					status: 'on',
				});
				setTranslateMode({
					enable: true,
					lang: {
						displayName: translateMode.lang.displayName || translateLangs[0].displayName,
						languageCode: translateMode.lang.languageCode || translateLangs[0].languageCode,
					},
				});

				const data = {
					enable: !translateMode.enable,
					lang: {
						displayName: translateMode.lang.displayName || translateLangs[0].displayName,
						languageCode: translateMode.lang.languageCode || translateLangs[0].languageCode,
					},
				};

				if (userData) userExtraDataPatchTranslateMode(userData, data);
				if (!translateMode.enable) translateMessages(translateMode.lang.languageCode);
			},
			250,
			{
				leading: false,
				trailing: true,
			}
		),
		[translateMode]
	);

	const onClickLanguageHandler = () => {
		toggleLanguagesModalVisible(true);
	};

	if (!languageModalVisible) {
		return null;
	}

	return (
		<Modal
			className='modal modal--langScreen'
			isOpen={languageModalVisible}
			ariaHideApp={false}
			style={{
				overlay: getOverlayStyle(),
				content: {
					width: getOverlayStyleWidth(),
					height: getOverlayStyleHeight(),
				},
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{languageScreen.title}</div>
				<Button className='modal__close' onClick={() => toggleLanguageModal(false)}>
					{getAppIcon(IcoClose.pic)}
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					{/* <p>{languageScreen.messagesTranslate}</p> */}
					<div className='settings'>
						<div className='settings__items'>
							<div className='settings__item settings__item--switcher'>
								<div className='settings__item-title'>{languageScreen.translateMessagesMode}</div>
								<div className='settings__item-switcher'>
									<Switcher
										checked={translateMode.enable}
										onChange={onChangeTranslateSwitcherHandler}
									/>
								</div>
							</div>
							<div className='settings__item'>
								<Button className='settings__button' onClick={onClickLanguageHandler}>
									{translateMode.lang.displayName || translateLangs[0].displayName}
									<span className='settings__button-arrow'>{getAppIcon(IcoChevron.pic)}</span>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(LanguageModal);
