/* eslint-disable react/no-danger */
import {MODAL_STYLE} from 'constants/modalOverlay';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import appService from 'store/appService';
import userService from 'store/userService';
import modalService from 'store/modalService';
import UserService from 'services/api/UserService';
import useSocketsHandlers from 'hooks/useSocketsHandlers';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import {Checkbox} from 'components/ui';
import useUser from 'hooks/useUser';
import useAnalytics from 'hooks/useAnalytics';

const AgreementAndChatRulesModal: FunctionComponent = function AgreementAndChatRulesModal() {
	const {modals} = useL10n();
	const [checkboxChecked, setCheckboxChecked] = useState(true);
	const {rules, appEnableWelcome} = useLocalObservable(() => appService);
	const {accessToken, userData, updateUserData} = useLocalObservable(() => userService);
	const {agreementAndChatRulesModal, toggleAgreementAndChatRulesModal, toggleRulesModal} =
		useLocalObservable(() => modalService);
	const {showUserNameInChat} = useSocketsHandlers();
	const {userExtraDataCheckWelcome, userExtraDataCheckOffer, userExtraDataCheckBadge} = useUser();
	const {sendAnalytics} = useAnalytics();

	const onShowRulesHandler = () => {
		toggleRulesModal(true);
	};

	const onCheckboxHandler = () => {
		setCheckboxChecked(!checkboxChecked);
	};

	const onAgreementAndChatRulesHandler = async () => {
		if (checkboxChecked) {
			const userExtraDataParse = userData?.extraData ? JSON.parse(userData.extraData) : {};
			const userExtraDataFindRules = Object.keys(userExtraDataParse).find(item => item === 'rules');
			let extraDataForPatchUser = {};

			if (userExtraDataParse) {
				if (userExtraDataFindRules) {
					extraDataForPatchUser = {
						...userExtraDataParse,
						rules: {...userExtraDataParse.rules, agreementAndChat: true},
					};
				} else {
					extraDataForPatchUser = {
						...userExtraDataParse,
						rules: {agreementAndChat: true},
					};
				}
			} else {
				extraDataForPatchUser = {rules: {agreementAndChat: true}};
			}

			const response = await UserService.patchUserData(
				{extraData: JSON.stringify(extraDataForPatchUser)},
				accessToken
			);
			if (response.status === ResponseStatus.SUCCESS) {
				updateUserData(response.data);
				showUserNameInChat();
			}

			sendAnalytics('agreement_accepted');
			toggleAgreementAndChatRulesModal(false);
		}
	};

	const onAfterCloseHandler = () => {
		if (userData) {
			if (appEnableWelcome) userExtraDataCheckWelcome(userData, false);
			if (!appEnableWelcome && userData.badges?.length) userExtraDataCheckBadge(userData, false);
		}
	};

	if (!agreementAndChatRulesModal) {
		return null;
	}

	return (
		<Modal
			className='modal modal--agreement'
			isOpen={agreementAndChatRulesModal}
			onAfterClose={onAfterCloseHandler}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.temporaryAgreement.title}</div>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='chat-rules'>
						<div className='chat-rules__items'>
							<div className='chat-rules__item'>
								<p className='chat-rules__item-title'>
									{modals.temporaryAgreement.rules.bePolite.title}
								</p>
								<p className='chat-rules__item-text'>
									{modals.temporaryAgreement.rules.bePolite.text}
								</p>
							</div>
							<div className='chat-rules__item'>
								<p className='chat-rules__item-title'>
									{modals.temporaryAgreement.rules.beAttentive.title}
								</p>
								<p className='chat-rules__item-text'>
									{modals.temporaryAgreement.rules.beAttentive.text}
								</p>
							</div>
							<div className='chat-rules__item'>
								<p className='chat-rules__item-title'>
									{modals.temporaryAgreement.rules.notAdvertise.title}
								</p>
								<p className='chat-rules__item-text'>
									{modals.temporaryAgreement.rules.notAdvertise.text}
								</p>
							</div>
						</div>
						{rules.rulesHTML && (
							<Button className='chat-rules__btn' onClick={onShowRulesHandler}>
								{modals.temporaryAgreement.btns.allRules}
							</Button>
						)}
					</div>
				</div>
			</div>
			<div className='modal__footer'>
				{rules.policyHTML && (
					<div className='modal__footer-content'>
						<Checkbox checked={checkboxChecked} onChange={onCheckboxHandler} />
						<p
							className='modal__footer-text'
							dangerouslySetInnerHTML={{
								__html: rules.policyHTML,
							}}
						/>
					</div>
				)}
				<Button
					className='modal__footer-btn btn'
					onClick={onAgreementAndChatRulesHandler}
					disabled={!checkboxChecked}>
					{modals.temporaryAgreement.btns.acceptAndContinue}
				</Button>
			</div>
		</Modal>
	);
};

export default observer(AgreementAndChatRulesModal);
