/* eslint-disable max-lines */
import UserRole from 'models/enums/UserRole.enum';
import AnonimAva from 'assets/images/anonim-ava.jpg';

export const USER_UNNAMED = 'Имя';
export const LINK_PREVIEW_PROXY = 'https://proxy.watchers.io/?url=';

// for yandex cloud
export const IMAGE_FOR_SPEEDTEST_YANDEX = 'https://storage.yandexcloud.net/watchers/test.jpg';

// for aws
export const IMAGE_FOR_SPEEDTEST_AWS =
	'https://webbackend-master.s3.eu-central-1.amazonaws.com/a7c1fcd2-2bff-4cbb-8b1b-069792cf2de5.jpeg';

export const USER_STUB = {
	id: 5146834951386002,
	pic: AnonimAva,
	name: 'Watchers user002',
	color: 0,
	email: '',
	isModer: false,
	bans: [],
};

export const TALKER_STUB = {
	id: 5146834951386001,
	hand: false,
	role: UserRole.GUEST,
	user: USER_STUB,
	isModer: false,
	isMuted: true,
	isActive: false,
	bans: [],
	isSupressed: false,
};

export const START_NUMBER_OF_MESSAGES = 40;
export const MAXIMUM_NUMBER_OF_MESSAGES = 60;
export const NUMBER_OF_MESSAGES_TO_DELETE = 20;
export const NUMBER_OF_MESSAGES_TO_DOWNLOAD = 20;

export const MAX_TEXTAREA_LENGTH = 500;
export const MAX_TEXTAREA_LENGTH_FOR_MODERATOR = 1500;
export const MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER = 449;
export const MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER_FOR_MODERATOR = 1449;
export const MAX_TEXTAREA_LENGTH_WARNING_TIMEOUT = 500;

export const localeLanguages = [
	'ru-RU',
	'en-GB',
	'sr-Latn-RS',
	'es-CL',
	'de-AT',
	'ar-XB',
	'ro-RO',
	'hy-AM',
	'tr-TR',
	'hi-IN',
	'pt-BR',
	'zh-CN',
	'ms-MY',
	'bn-BD',
	'th-TH',
	'fr-FR',
	'fi-FI',
	'is-IS',
	'ko-KR',
	'pl-PL',
	'az-AZ',
	'uz-UZ',
	'uk-UA',
	'ja-JP',
];

export const rtlLanguages = ['ar', 'he', 'fa', 'ur'];
