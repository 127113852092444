import classNames from 'classnames';
import Button from 'components/hoc/Button';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import appService from 'store/appService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import streamService from 'store/streamService';
import {VideoPosition} from 'models/enums/VideoPosition.enum';
import modalService from 'store/modalService';
import userService from 'store/userService';
import useOnboarding from 'hooks/useOnboarding';
import useTranslation from 'hooks/useTranslation';
import useUser from 'hooks/useUser';
import actionMessageService from 'store/actionMessageService';
import controlPanelService from 'store/controlPanelService';
import threadService from 'store/threadService';
import SocketIoServices from 'services/SocketIoServices';
import roomService from 'store/roomService';

const ThreadHeader: FunctionComponent = function ThreadHeader() {
	const {threads} = useL10n();
	const {appEnableMessageTranslation, appIcons, videoPosition, appEnableOnboarding} =
		useLocalObservable(() => appService);
	const {isLayoutSwitched, isScreenSharing} = useLocalObservable(() => streamService);
	const {agreementAndChatRulesModal, toggleTranslateModalVisible, toggleVisibleThreadModal} =
		useLocalObservable(() => modalService);
	const {translateMode, userData, setTranslateMode} = useLocalObservable(() => userService);
	const {myTalker} = useLocalObservable(() => roomService);
	const {isVisibleActionMessage, toggleIsVisibleActionMessage} = useLocalObservable(
		() => actionMessageService
	);
	const {toggleVisibleButtons} = useLocalObservable(() => controlPanelService);
	const {currentThreadId, setCurrentThreadId, clearTread} = useLocalObservable(() => threadService);

	const {getAppIcon} = useAppData();
	const {IcoChevron, IcoTranslateOn, IcoTranslateOff} = appIcons;
	const checkUserIsOnboarding =
		!appEnableOnboarding || (userData?.isOnboard && appEnableOnboarding);

	const {onBoardingStageHandler} = useOnboarding();
	const {translateMessages} = useTranslation();
	const {userExtraDataPatchTranslateMode} = useUser();

	const headerThreadClasses = classNames('thread-modal__header', {
		'thread-modal__header--switched-layout': isLayoutSwitched,
		'thread-modal__header--stream-mode':
			isScreenSharing && videoPosition !== VideoPosition.FLOATING,
	});

	const onCloseThreadHandler = () => {
		if (isVisibleActionMessage) toggleIsVisibleActionMessage(false);

		toggleVisibleThreadModal(false);
		setCurrentThreadId(null);
		clearTread();
		if (!myTalker?.bans?.length && !myTalker?.user?.bans?.length) toggleVisibleButtons(true);

		if (currentThreadId && currentThreadId !== 'new') SocketIoServices.emitLeave(currentThreadId);
	};

	const translateBtnClickHandler = () => {
		if (!translateMode.enable && translateMode.lang.languageCode)
			translateMessages(translateMode.lang.languageCode);
		if (!translateMode.lang.languageCode) {
			toggleTranslateModalVisible(true);
			return;
		}
		setTranslateMode({enable: !translateMode.enable});
		if (userData)
			userExtraDataPatchTranslateMode(userData, {...translateMode, enable: !translateMode.enable});
	};

	return (
		<div className={headerThreadClasses}>
			<div className='thread-modal__left'>
				<Button className='thread-modal__close-thread' onClick={onCloseThreadHandler}>
					<span className='thread-modal__close-thread-arrow'>{getAppIcon(IcoChevron.pic)}</span>
					{threads.back}
				</Button>
			</div>
			<p className='thread-modal__thread-title'>{threads.title}</p>

			<div className='thread-modal__right'>
				{appEnableMessageTranslation && !agreementAndChatRulesModal && (
					<div className='thread-modal__translate-wrapper'>
						<Button
							className='thread-modal__translate-btn'
							onClick={checkUserIsOnboarding ? translateBtnClickHandler : onBoardingStageHandler}>
							{!translateMode.enable
								? getAppIcon(IcoTranslateOn.pic)
								: getAppIcon(IcoTranslateOff.pic)}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default observer(ThreadHeader);
